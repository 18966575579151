<template>
  <div
    class="o-footer u-hide@tabletPortraitDown"
    data-cy="DefaultFooter">
    <div
      class="o-footer__section t-caption1"
      data-cy="DefaultFooter__appnameAndVersion">
      {{ config.name }}
      <div class="t-caption2">
        Version {{ $constants.VERSION }}
      </div>
    </div>

    <div
      v-if="pages && pages.length"
      class="o-footer__section o-flex -column">
      <router-link
        v-for="page in pages"
        :key="page.id"
        class="o-footer__link t-caption1 t-fontWeightMedium u-marginBottom1"
        data-cy="DefaultFooter__page"
        :to="{ name: 'pages.details', params: { id: page.id } }">
        {{ page.title }}
      </router-link>
    </div>

    <div
      v-if="socialMediaEntries.length"
      class="o-footer__section o-flex -row">
      <a
        v-for="entry in socialMediaEntries"
        :key="entry.type"
        :href="entry.url"
        target="_blank"
        rel="noopener noreferrer"
        class="o-footer__socialMediaIcon u-marginRight4">
        <img
          :alt="entry.type"
          data-cy="DefaultFooter__socialMediaIcon"
          :src="entry.icon" />
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import facebookIcon from '@/assets/images/social_media/facebook.svg'
import instagramIcon from '@/assets/images/social_media/instagram.svg'
import linkedinIcon from '@/assets/images/social_media/linkedin.svg'
import tiktokIcon from '@/assets/images/social_media/tiktok.svg'
import twitterIcon from '@/assets/images/social_media/twitter.svg'
import youtubeIcon from '@/assets/images/social_media/youtube.svg'

const icons = {
  facebook: facebookIcon,
  twitter: twitterIcon,
  instagram: instagramIcon,
  youtube: youtubeIcon,
  linkedin: linkedinIcon,
  tiktok: tiktokIcon,
}
export default {
  computed: {
    ...mapGetters({
      pages: 'pages/footerPagesList',
      config: 'auth/config',
    }),
    socialMediaEntries() {
      const entries = [
        'facebook',
        'instagram',
        'twitter',
        'youtube',
        'linkedin',
        'tiktok',
      ]

      return entries.map((entry) => {
        if (!this.config.settings[`${entry}_url`]) {
          return null
        }
        return {
          type: entry,
          url: this.config.settings[`${entry}_url`],
          icon: icons[entry],
        }
      }).filter((entry) => entry !== null)
    },
  },
}
</script>
