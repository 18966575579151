<template>
  <svg
    viewBox="0 0 512 512"
    class="c-icon"
    :class="{
      '-spinning': spinning,
      '-huge': huge,
      '-large': large,
      '-larger': larger,
      '-medium': medium,
      '-small': small,
      '-tiny': tiny,
      '-mirrored': mirrored,
      '-inWhiteCircle': inWhiteCircle,
    }"
    focusable="false">
    <use :xlink:href="xlink" />
  </svg>
</template>

<script>
export default {
  props: {
    type: {
      required: true,
      type: String,
    },
    spinning: {
      default: false,
      type: Boolean,
    },
    huge: {
      required: false,
      type: Boolean,
    },
    large: {
      required: false,
      type: Boolean,
    },
    larger: {
      required: false,
      type: Boolean,
    },
    medium: {
      required: false,
      type: Boolean,
    },
    small: {
      required: false,
      type: Boolean,
    },
    tiny: {
      required: false,
      type: Boolean,
    },
    mirrored: {
      required: false,
      type: Boolean,
    },
    inWhiteCircle: {
      required: false,
      type: Boolean,
    },
  },
  computed: {
    xlink() {
      return `/img/icons.svg#${this.computedType}`
    },
    computedType() {
      const aliases = {
        type_document_word: 'type_document',
        type_document_image: 'type_document',
        type_document_pdf: 'type_document',
        type_document_powerpoint: 'type_document',
      }
      if (typeof aliases[this.type] !== 'undefined') {
        return aliases[this.type]
      }
      return this.type
    },
  },
}
</script>
