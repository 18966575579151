export default {
  hasConfig: (state) => state.config !== null,
  config: (state) => state.config,
  appId: (state) => state.config?.id,
  profileId: (state) => state.config?.profile_id,
  isLoggedIn: (state) => state.token !== null && state.user !== null,
  token: (state) => state.token,
  userId: (state) => state.user.id,
  avatar: (state) => state.user?.avatar,
  email: (state) => state.user.email,
  isActive: (state) => state.user.active,
  isTmpAccount: (state) => state.user.tmpAccount,
  isAdmin: (state) => state.user.is_admin,
  locale: (state) => state.locale,
  browserLocaleDetected: (state) => state.browserLocaleDetected,
  localeManuallySelected: (state) => state.localeManuallySelected,
  authPages: (state) => state.authPages,
  fcmToken: (state) => state.fcmToken,
  username(state) {
    if (!state.user) {
      return ''
    }
    return state.user.name
  },
  displayname(state, getters) {
    if (!state.user) {
      return ''
    }
    if (!state.user.displayname) {
      return getters.username
    }
    return state.user.displayname
  },
  hasModule(state) {
    const hasModules = {}
    if (!state.config) {
      return hasModules
    }
    Object.keys(state.config.settings).forEach((setting) => {
      if (setting.indexOf('module_') === 0) {
        hasModules[setting.substring(7)] = !!state.config.settings[setting]
      }
    })
    return hasModules
  },
  hideQuizStatistics: (state) => state.config.settings.quiz_hide_player_statistics,
  webAppURL(state) {
    if (state.config.settings.external_domain) {
      return `https://${state.config.settings.external_domain}`
    }
    return `https://${state.config.settings.subdomain}.keelearning.de`
  },
}
