var render = function render(){var _vm=this,_c=_vm._self._c;return _c('svg',{staticClass:"c-icon",class:{
    '-spinning': _vm.spinning,
    '-huge': _vm.huge,
    '-large': _vm.large,
    '-larger': _vm.larger,
    '-medium': _vm.medium,
    '-small': _vm.small,
    '-tiny': _vm.tiny,
    '-mirrored': _vm.mirrored,
    '-inWhiteCircle': _vm.inWhiteCircle,
  },attrs:{"viewBox":"0 0 512 512","focusable":"false"}},[_c('use',{attrs:{"xlink:href":_vm.xlink}})])
}
var staticRenderFns = []

export { render, staticRenderFns }