import DateManager from './DateManager'

const api = {
  lastErrorShown: null,
  lastConnectionErrorShown: null,
  store: null,
  i18n: null,
  router: null,
  isUnloading: false,
  setup(store, i18n, router) {
    this.store = store
    this.i18n = i18n
    this.router = router
    window.addEventListener('beforeunload', () => {
      this.isUnloading = true
    })
  },
  get(url, params) {
    if (this.store.getters.isOffline) {
      return Promise.reject()
    }

    const headers = this.getDefaultHeaders()
    return new Promise((resolve, reject) => {
      fetch(this.getURL(url, params), {
        headers,
        method: 'GET',
      }).then(
        (response) => {
          if (!response.ok) {
            if (this.handleResponseError(response)) {
              this.getErrorResponse(response).then(reject)
            }
            return
          }
          this.handleResponseDate(response)
          response
            .json()
            .then(resolve)
            .catch(reject)
        },
        (error) => {
          this.handleConnectionError(error)
          reject(error)
        },
      )
    })
  },
  send(url, body, method, customHeaders = null) {
    if (this.store.getters.isOffline) {
      return Promise.reject()
    }

    const headers = this.getDefaultHeaders()
    headers['Content-Type'] = 'application/json'

    if (customHeaders) {
      Object.entries(customHeaders).forEach(([key, value]) => {
        if (value === undefined) {
          delete headers[key]
        } else {
          headers[key] = value
        }
      })
    }

    if (headers['Content-Type'] === 'application/json') {
      body = JSON.stringify(body)
    }

    return new Promise((resolve, reject) => {
      fetch(api.getURL(url), {
        body,
        headers,
        method: method || 'POST',
      }).then(
        (response) => {
          if (!response.ok) {
            this.handleResponseError(response)
            this.getErrorResponse(response).then(reject)
            return
          }
          response
            .json()
            .then(resolve)
            .catch(reject)
        },
        (error) => {
          this.handleConnectionError(error)
          reject(error)
        },
      )
    })
  },
  post(url, body, customHeaders = null) {
    return this.send(url, body, 'POST', customHeaders)
  },
  delete(url, body) {
    return this.send(url, body, 'DELETE')
  },
  getFlatbuffer(url) {
    if (this.store.getters.isOffline) {
      return Promise.reject()
    }
    const headers = this.getFlatbuffersHeaders()
    const flatbuffersModule = import('flatbuffers')
    return new Promise((resolve, reject) => {
      fetch(this.getURL(url, { flatbuffers: 1 }), {
        headers,
        method: 'GET',
      }).then(
        (response) => {
          if (!response.ok) {
            if (this.handleResponseError(response)) {
              this.getErrorResponse(response).then(reject)
            }
            return
          }
          this.handleResponseDate(response)
          response
            .arrayBuffer()
            .then((result) => {
              flatbuffersModule.then((module) => {
                const dataBuffer = new module.flatbuffers.ByteBuffer(new Uint8Array(result))
                resolve(dataBuffer)
              })
            })
            .catch(reject)
        },
        (error) => {
          this.handleConnectionError(error)
          reject(error)
        },
      )
    })
  },
  getFile(url) {
    if (this.store.getters.isOffline) {
      return Promise.reject()
    }
    return new Promise((resolve, reject) => {
      fetch(this.getURL(url), {
        method: 'GET',
      }).then(
        (response) => {
          if (!response.ok) {
            this.handleResponseError(response)
            this.getErrorResponse(response).then(reject)
            return
          }
          this.handleResponseDate(response)
          response
            .blob()
            .then(resolve)
            .catch(reject)
        },
        (error) => {
          this.handleConnectionError(error)
          reject(error)
        },
      )
    })
  },
  handleResponseDate(response) {
    if (response.headers.has('Date')) {
      DateManager.set(response.headers.get('Date'))
    }
  },
  handleResultMeta(meta) {
    if (meta.token) {
      this.setToken(meta.token)
    }
  },
  handleResponseError(response) {
    if (this.isUnloading) {
      return false
    }

    if (response.headers.get('x-logout-user')) {
      this.store.dispatch('auth/logout')
    } else if (response.headers.get('x-tos-not-accepted')) {
      if (this.router.app.$route.meta.requiresAuth === false) {
        return false
      }
      this.router.push({
        name: 'auth.tos',
      })
    } else if (response.headers.get('x-password-not-reset')) {
      this.router.push({
        name: 'auth.forcepasswordreset',
      })
    }
    // If the last error was shown less then 1.5 seconds ago, we don't show another one
    const currentDate = Date.now()
    if (this.lastErrorShown && currentDate - this.lastErrorShown < 1500) {
      return false
    }
    this.lastErrorShown = currentDate

    return true
  },
  getErrorResponse(response) {
    try {
      return response.json().then((res) => {
        let returnResponse = res

        if (!res.meta || Object.keys(res.meta).length === 0) {
          returnResponse = res.message
        }
        if (returnResponse) {
          return returnResponse
        }
        return this.i18n.t('errors.no_response')
      })
    } catch (e) {
      return Promise.resolve(this.i18n.t('errors.unknown_error'))
    }
  },
  handleConnectionError() {
    if (this.isUnloading) {
      return false
    }
    if (window.navigator.onLine) {
      // If the last error was shown less then 3 seconds ago, we don't show another one
      const currentDate = Date.now()
      if (this.lastConnectionErrorShown && currentDate - this.lastConnectionErrorShown < 3000) {
        return false
      }
      this.lastConnectionErrorShown = currentDate
      this.store.commit('notifications/addNotification', {
        topic: this.i18n.t('notifications.error'),
        title: 'connection error',
      })
    }
    return true
  },
  getURL(path, params) {
    let parsedParams = ''
    if (typeof params !== 'undefined' && Object.keys(params).length > 0) {
      parsedParams += '?'
      parsedParams += Object.keys(params)
        .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
        .join('&')
    }
    let domain = process.env.VUE_APP_API_URL
    if (path.indexOf('http://') === 0 || path.indexOf('https://') === 0) {
      domain = ''
    }
    return domain + path + parsedParams
  },
  getDefaultHeaders() {
    const headers = {
      Accept: 'application/json',
      ...this.getAuthHeader(),
      ...this.getLanguageHeader(),
      ...this.getAPIVersionHeader(),
    }
    return headers
  },
  getFlatbuffersHeaders() {
    const headers = {
      ...this.getAuthHeader(),
      ...this.getLanguageHeader(),
      ...this.getAPIVersionHeader(),
    }
    return headers
  },
  getAuthHeader() {
    if (this.getToken()) {
      return { 'X-QUIZAPP-AUTHORIZATION': `Bearer ${this.getToken()}` }
    }
    return {}
  },
  getLanguageHeader() {
    return { 'X-LANGUAGE': this.i18n.locale }
  },
  getAPIVersionHeader() {
    return { 'X-API-VERSION': '3.5.0' }
  },
  getToken() {
    return this.store.state.auth.token
  },
  setToken(token) {
    if (!process.browser) {
      return null
    }
    return this.store.commit('auth/setToken', token)
  },
}

export default api
